// DO NOT EDIT THIS FILE
// This file has been generated by gen-api-models
// eslint-disable sonar/max-union-size
// eslint-disable sonarjs/no-identical-functions

import * as t from "io-ts";

import * as r from "@pagopa/ts-commons/lib/requests";

import { ProblemJson } from "./ProblemJson";

import { Brokers } from "./Brokers";

import { BrokerDetails } from "./BrokerDetails";

import { StationMaintenanceListResource } from "./StationMaintenanceListResource";

import { CreateStationMaintenance } from "./CreateStationMaintenance";

import { StationMaintenanceResource } from "./StationMaintenanceResource";

import { MaintenanceHoursSummaryResource } from "./MaintenanceHoursSummaryResource";

import { UpdateStationMaintenance } from "./UpdateStationMaintenance";

import { BrokersPsp } from "./BrokersPsp";

import { BrokerPspDetails } from "./BrokerPspDetails";

import { PaymentServiceProviders } from "./PaymentServiceProviders";

import { CacheVersions } from "./CacheVersions";

import { Cache } from "./Cache";

import { Cdis } from "./Cdis";

import { Channels } from "./Channels";

import { ChannelDetails } from "./ChannelDetails";

import { ChannelPspList } from "./ChannelPspList";

import { PspChannelPaymentTypes } from "./PspChannelPaymentTypes";

import { FtpServers } from "./FtpServers";

import { FtpServer } from "./FtpServer";

import { ConfigurationKeys } from "./ConfigurationKeys";

import { ConfigurationKey } from "./ConfigurationKey";

import { ConfigurationKeyBase } from "./ConfigurationKeyBase";

import { PaymentTypes } from "./PaymentTypes";

import { PaymentType } from "./PaymentType";

import { PaymentTypeBase } from "./PaymentTypeBase";

import { Pdds } from "./Pdds";

import { Pdd } from "./Pdd";

import { PddBase } from "./PddBase";

import { WfespPluginConfs } from "./WfespPluginConfs";

import { WfespPluginConf } from "./WfespPluginConf";

import { WfespPluginConfBase } from "./WfespPluginConfBase";

import { CounterpartTables } from "./CounterpartTables";

import { CreditorInstitutions } from "./CreditorInstitutions";

import { CreditorInstitutionDetails } from "./CreditorInstitutionDetails";

import { IbanLabel } from "./IbanLabel";

import { CreditorInstitutionsView } from "./CreditorInstitutionsView";

import { CreditorInstitutionEncodings } from "./CreditorInstitutionEncodings";

import { Encoding } from "./Encoding";

import { Ibans } from "./Ibans";

import { IbanEnhanced } from "./IbanEnhanced";

import { IbansEnhanced } from "./IbansEnhanced";

import { CreditorInstitutionStationList } from "./CreditorInstitutionStationList";

import { CreditorInstitutionStationEdit } from "./CreditorInstitutionStationEdit";

import { CreditorInstitutionList } from "./CreditorInstitutionList";

import { Icas } from "./Icas";

import { XSDValidation } from "./XSDValidation";

import { AppInfo } from "./AppInfo";

import { PaymentServiceProviderDetails } from "./PaymentServiceProviderDetails";

import { PaymentServiceProvidersView } from "./PaymentServiceProvidersView";

import { PspChannelList } from "./PspChannelList";

import { PspChannelCode } from "./PspChannelCode";

import { Services } from "./Services";

import { Stations } from "./Stations";

import { StationDetails } from "./StationDetails";

import { StationCreditorInstitutions } from "./StationCreditorInstitutions";

/****************************************************************
 * manageCIStationRelationship
 */

// Request type definition
export type ManageCIStationRelationshipT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const manageCIStationRelationshipDefaultResponses = {
  201: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type ManageCIStationRelationshipResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function manageCIStationRelationshipDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        ManageCIStationRelationshipResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((manageCIStationRelationshipDefaultResponses as unknown) as ManageCIStationRelationshipResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const manageCIStationRelationshipDefaultDecoder = () =>
  manageCIStationRelationshipDecoder();

/****************************************************************
 * massiveMigration
 */

// Request type definition
export type MassiveMigrationT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const massiveMigrationDefaultResponses = {
  201: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type MassiveMigrationResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function massiveMigrationDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        MassiveMigrationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((massiveMigrationDefaultResponses as unknown) as MassiveMigrationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const massiveMigrationDefaultDecoder = () => massiveMigrationDecoder();

/****************************************************************
 * getBrokers
 */

// Request type definition
export type GetBrokersT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly code?: string;
    readonly name?: string;
    readonly orderby?: string;
    readonly ordering?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Brokers, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getBrokersDefaultResponses = {
  200: Brokers,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetBrokersResponsesT<
  A0 = Brokers,
  C0 = Brokers,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getBrokersDecoder<
  A0 = Brokers,
  C0 = Brokers,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetBrokersResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBrokersDefaultResponses as unknown) as GetBrokersResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getBrokersDefaultDecoder = () => getBrokersDecoder();

/****************************************************************
 * createBroker
 */

// Request type definition
export type CreateBrokerT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: BrokerDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, BrokerDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createBrokerDefaultResponses = {
  201: BrokerDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateBrokerResponsesT<
  A0 = BrokerDetails,
  C0 = BrokerDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createBrokerDecoder<
  A0 = BrokerDetails,
  C0 = BrokerDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateBrokerResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createBrokerDefaultResponses as unknown) as CreateBrokerResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createBrokerDefaultDecoder = () => createBrokerDecoder();

/****************************************************************
 * deleteBroker
 */

// Request type definition
export type DeleteBrokerT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokercode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteBrokerDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteBrokerResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteBrokerDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteBrokerResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteBrokerDefaultResponses as unknown) as DeleteBrokerResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteBrokerDefaultDecoder = () => deleteBrokerDecoder();

/****************************************************************
 * getBroker
 */

// Request type definition
export type GetBrokerT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokercode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, BrokerDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getBrokerDefaultResponses = {
  200: BrokerDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetBrokerResponsesT<
  A0 = BrokerDetails,
  C0 = BrokerDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getBrokerDecoder<
  A0 = BrokerDetails,
  C0 = BrokerDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetBrokerResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBrokerDefaultResponses as unknown) as GetBrokerResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getBrokerDefaultDecoder = () => getBrokerDecoder();

/****************************************************************
 * updateBroker
 */

// Request type definition
export type UpdateBrokerT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokercode: string;
    readonly body: BrokerDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, BrokerDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateBrokerDefaultResponses = {
  200: BrokerDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateBrokerResponsesT<
  A0 = BrokerDetails,
  C0 = BrokerDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateBrokerDecoder<
  A0 = BrokerDetails,
  C0 = BrokerDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateBrokerResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateBrokerDefaultResponses as unknown) as UpdateBrokerResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateBrokerDefaultDecoder = () => updateBrokerDecoder();

/****************************************************************
 * getStationMaintenances
 */

// Request type definition
export type GetStationMaintenancesT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokercode: string;
    readonly stationCode?: string;
    readonly startDateTimeBefore?: string;
    readonly startDateTimeAfter?: string;
    readonly endDateTimeBefore?: string;
    readonly endDateTimeAfter?: string;
    readonly limit?: number;
    readonly page?: number;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, StationMaintenanceListResource, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getStationMaintenancesDefaultResponses = {
  200: StationMaintenanceListResource,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetStationMaintenancesResponsesT<
  A0 = StationMaintenanceListResource,
  C0 = StationMaintenanceListResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getStationMaintenancesDecoder<
  A0 = StationMaintenanceListResource,
  C0 = StationMaintenanceListResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetStationMaintenancesResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getStationMaintenancesDefaultResponses as unknown) as GetStationMaintenancesResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getStationMaintenancesDefaultDecoder = () =>
  getStationMaintenancesDecoder();

/****************************************************************
 * createStationMaintenance
 */

// Request type definition
export type CreateStationMaintenanceT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokercode: string;
    readonly body: CreateStationMaintenance;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, StationMaintenanceResource, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createStationMaintenanceDefaultResponses = {
  201: StationMaintenanceResource,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateStationMaintenanceResponsesT<
  A0 = StationMaintenanceResource,
  C0 = StationMaintenanceResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createStationMaintenanceDecoder<
  A0 = StationMaintenanceResource,
  C0 = StationMaintenanceResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateStationMaintenanceResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createStationMaintenanceDefaultResponses as unknown) as CreateStationMaintenanceResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createStationMaintenanceDefaultDecoder = () =>
  createStationMaintenanceDecoder();

/****************************************************************
 * getBrokerMaintenancesSummary
 */

// Request type definition
export type GetBrokerMaintenancesSummaryT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokercode: string;
    readonly maintenanceYear: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, MaintenanceHoursSummaryResource, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getBrokerMaintenancesSummaryDefaultResponses = {
  200: MaintenanceHoursSummaryResource,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetBrokerMaintenancesSummaryResponsesT<
  A0 = MaintenanceHoursSummaryResource,
  C0 = MaintenanceHoursSummaryResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getBrokerMaintenancesSummaryDecoder<
  A0 = MaintenanceHoursSummaryResource,
  C0 = MaintenanceHoursSummaryResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetBrokerMaintenancesSummaryResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBrokerMaintenancesSummaryDefaultResponses as unknown) as GetBrokerMaintenancesSummaryResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getBrokerMaintenancesSummaryDefaultDecoder = () =>
  getBrokerMaintenancesSummaryDecoder();

/****************************************************************
 * deleteStationMaintenance
 */

// Request type definition
export type DeleteStationMaintenanceT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokercode: string;
    readonly maintenanceid: number;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteStationMaintenanceDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteStationMaintenanceResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteStationMaintenanceDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteStationMaintenanceResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteStationMaintenanceDefaultResponses as unknown) as DeleteStationMaintenanceResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteStationMaintenanceDefaultDecoder = () =>
  deleteStationMaintenanceDecoder();

/****************************************************************
 * getStationMaintenance
 */

// Request type definition
export type GetStationMaintenanceT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokercode: string;
    readonly maintenanceid: number;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, StationMaintenanceResource, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getStationMaintenanceDefaultResponses = {
  200: StationMaintenanceResource,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetStationMaintenanceResponsesT<
  A0 = StationMaintenanceResource,
  C0 = StationMaintenanceResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getStationMaintenanceDecoder<
  A0 = StationMaintenanceResource,
  C0 = StationMaintenanceResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetStationMaintenanceResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getStationMaintenanceDefaultResponses as unknown) as GetStationMaintenanceResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getStationMaintenanceDefaultDecoder = () =>
  getStationMaintenanceDecoder();

/****************************************************************
 * updateStationMaintenance
 */

// Request type definition
export type UpdateStationMaintenanceT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokercode: string;
    readonly maintenanceid: number;
    readonly body: UpdateStationMaintenance;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, StationMaintenanceResource, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateStationMaintenanceDefaultResponses = {
  200: StationMaintenanceResource,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateStationMaintenanceResponsesT<
  A0 = StationMaintenanceResource,
  C0 = StationMaintenanceResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateStationMaintenanceDecoder<
  A0 = StationMaintenanceResource,
  C0 = StationMaintenanceResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateStationMaintenanceResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateStationMaintenanceDefaultResponses as unknown) as UpdateStationMaintenanceResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateStationMaintenanceDefaultDecoder = () =>
  updateStationMaintenanceDecoder();

/****************************************************************
 * getBrokersPsp
 */

// Request type definition
export type GetBrokersPspT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly code?: string;
    readonly name?: string;
    readonly orderby?: string;
    readonly ordering?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, BrokersPsp, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getBrokersPspDefaultResponses = {
  200: BrokersPsp,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetBrokersPspResponsesT<
  A0 = BrokersPsp,
  C0 = BrokersPsp,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getBrokersPspDecoder<
  A0 = BrokersPsp,
  C0 = BrokersPsp,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetBrokersPspResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBrokersPspDefaultResponses as unknown) as GetBrokersPspResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getBrokersPspDefaultDecoder = () => getBrokersPspDecoder();

/****************************************************************
 * createBrokerPsp
 */

// Request type definition
export type CreateBrokerPspT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: BrokerPspDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, BrokerPspDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createBrokerPspDefaultResponses = {
  201: BrokerPspDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateBrokerPspResponsesT<
  A0 = BrokerPspDetails,
  C0 = BrokerPspDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createBrokerPspDecoder<
  A0 = BrokerPspDetails,
  C0 = BrokerPspDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateBrokerPspResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createBrokerPspDefaultResponses as unknown) as CreateBrokerPspResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createBrokerPspDefaultDecoder = () => createBrokerPspDecoder();

/****************************************************************
 * deleteBrokerPsp
 */

// Request type definition
export type DeleteBrokerPspT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokerpspcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteBrokerPspDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteBrokerPspResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteBrokerPspDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteBrokerPspResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteBrokerPspDefaultResponses as unknown) as DeleteBrokerPspResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteBrokerPspDefaultDecoder = () => deleteBrokerPspDecoder();

/****************************************************************
 * getBrokerPsp
 */

// Request type definition
export type GetBrokerPspT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokerpspcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, BrokerPspDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getBrokerPspDefaultResponses = {
  200: BrokerPspDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetBrokerPspResponsesT<
  A0 = BrokerPspDetails,
  C0 = BrokerPspDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getBrokerPspDecoder<
  A0 = BrokerPspDetails,
  C0 = BrokerPspDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetBrokerPspResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBrokerPspDefaultResponses as unknown) as GetBrokerPspResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getBrokerPspDefaultDecoder = () => getBrokerPspDecoder();

/****************************************************************
 * updateBrokerPsp
 */

// Request type definition
export type UpdateBrokerPspT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly brokerpspcode: string;
    readonly body: BrokerPspDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, BrokerPspDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateBrokerPspDefaultResponses = {
  200: BrokerPspDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateBrokerPspResponsesT<
  A0 = BrokerPspDetails,
  C0 = BrokerPspDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateBrokerPspDecoder<
  A0 = BrokerPspDetails,
  C0 = BrokerPspDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateBrokerPspResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateBrokerPspDefaultResponses as unknown) as UpdateBrokerPspResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateBrokerPspDefaultDecoder = () => updateBrokerPspDecoder();

/****************************************************************
 * getPspBrokerPsp
 */

// Request type definition
export type GetPspBrokerPspT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly brokerpspcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PaymentServiceProviders, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getPspBrokerPspDefaultResponses = {
  200: PaymentServiceProviders,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetPspBrokerPspResponsesT<
  A0 = PaymentServiceProviders,
  C0 = PaymentServiceProviders,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getPspBrokerPspDecoder<
  A0 = PaymentServiceProviders,
  C0 = PaymentServiceProviders,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetPspBrokerPspResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPspBrokerPspDefaultResponses as unknown) as GetPspBrokerPspResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getPspBrokerPspDefaultDecoder = () => getPspBrokerPspDecoder();

/****************************************************************
 * getCacheVersions
 */

// Request type definition
export type GetCacheVersionsT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page?: number;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CacheVersions, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCacheVersionsDefaultResponses = {
  200: CacheVersions,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetCacheVersionsResponsesT<
  A0 = CacheVersions,
  C0 = CacheVersions,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getCacheVersionsDecoder<
  A0 = CacheVersions,
  C0 = CacheVersions,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCacheVersionsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCacheVersionsDefaultResponses as unknown) as GetCacheVersionsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCacheVersionsDefaultDecoder = () => getCacheVersionsDecoder();

/****************************************************************
 * getCacheByVersion
 */

// Request type definition
export type GetCacheByVersionT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly version: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCacheByVersionDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetCacheByVersionResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getCacheByVersionDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCacheByVersionResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCacheByVersionDefaultResponses as unknown) as GetCacheByVersionResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCacheByVersionDefaultDecoder = () => getCacheByVersionDecoder();

/****************************************************************
 * getCacheId
 */

// Request type definition
export type GetCacheIdT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly version: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Cache, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCacheIdDefaultResponses = {
  200: Cache,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetCacheIdResponsesT<
  A0 = Cache,
  C0 = Cache,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getCacheIdDecoder<
  A0 = Cache,
  C0 = Cache,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCacheIdResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCacheIdDefaultResponses as unknown) as GetCacheIdResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCacheIdDefaultDecoder = () => getCacheIdDecoder();

/****************************************************************
 * getCdis
 */

// Request type definition
export type GetCdisT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly idcdi?: string;
    readonly pspcode?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Cdis, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCdisDefaultResponses = {
  200: Cdis,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetCdisResponsesT<
  A0 = Cdis,
  C0 = Cdis,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getCdisDecoder<
  A0 = Cdis,
  C0 = Cdis,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<GetCdisResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCdisDefaultResponses as unknown) as GetCdisResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCdisDefaultDecoder = () => getCdisDecoder();

/****************************************************************
 * createCdi
 */

// Request type definition
export type CreateCdiT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<422, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createCdiDefaultResponses = {
  201: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  422: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateCdiResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  422: t.Type<A5, C5>;
  429: t.Type<A6, C6>;
  500: t.Type<A7, C7>;
};

export function createCdiDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateCdiResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6,
          A7,
          C7
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<422, A5, "X-Request-Id">
  | r.IResponseType<429, A6, "X-Request-Id">
  | r.IResponseType<500, A7, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createCdiDefaultResponses as unknown) as CreateCdiResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6,
      A7,
      C7
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d422 = (type[422].name === "undefined"
    ? r.constantResponseDecoder<undefined, 422, "X-Request-Id">(422, undefined)
    : r.ioResponseDecoder<
        422,
        typeof type[422]["_A"],
        typeof type[422]["_O"],
        "X-Request-Id"
      >(422, type[422])) as r.ResponseDecoder<
    r.IResponseType<422, A5, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A6, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A7, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(
              r.composeResponseDecoders(d201, d400),
              d401
            ),
            d403
          ),
          d404
        ),
        d422
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createCdiDefaultDecoder = () => createCdiDecoder();

/****************************************************************
 * verifyCdi
 */

// Request type definition
export type VerifyCdiT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const verifyCdiDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type VerifyCdiResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function verifyCdiDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        VerifyCdiResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((verifyCdiDefaultResponses as unknown) as VerifyCdiResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const verifyCdiDefaultDecoder = () => verifyCdiDecoder();

/****************************************************************
 * uploadHistory_1
 */

// Request type definition
export type UploadHistory_1T = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const uploadHistory_1DefaultResponses = {
  200: t.undefined,
  401: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type UploadHistory_1ResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  429: t.Type<A2, C2>;
  500: t.Type<A3, C3>;
};

export function uploadHistory_1Decoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson
>(
  overrideTypes:
    | Partial<UploadHistory_1ResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<429, A2, "X-Request-Id">
  | r.IResponseType<500, A3, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((uploadHistory_1DefaultResponses as unknown) as UploadHistory_1ResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A2, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A3, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d429),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const uploadHistory_1DefaultDecoder = () => uploadHistory_1Decoder();

/****************************************************************
 * deleteCdi
 */

// Request type definition
export type DeleteCdiT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly idcdi: string;
    readonly pspcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteCdiDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteCdiResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  409: t.Type<A5, C5>;
  429: t.Type<A6, C6>;
  500: t.Type<A7, C7>;
};

export function deleteCdiDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteCdiResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6,
          A7,
          C7
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<409, A5, "X-Request-Id">
  | r.IResponseType<429, A6, "X-Request-Id">
  | r.IResponseType<500, A7, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteCdiDefaultResponses as unknown) as DeleteCdiResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6,
      A7,
      C7
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A5, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A6, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A7, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(
              r.composeResponseDecoders(d200, d400),
              d401
            ),
            d403
          ),
          d404
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteCdiDefaultDecoder = () => deleteCdiDecoder();

/****************************************************************
 * getCdi
 */

// Request type definition
export type GetCdiT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly idcdi: string;
    readonly pspcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCdiDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetCdiResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getCdiDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCdiResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5, A6, C6>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCdiDefaultResponses as unknown) as GetCdiResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCdiDefaultDecoder = () => getCdiDecoder();

/****************************************************************
 * getChannels
 */

// Request type definition
export type GetChannelsT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly brokercode?: string;
    readonly brokerdescription?: string;
    readonly code?: string;
    readonly ordering?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Channels, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getChannelsDefaultResponses = {
  200: Channels,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetChannelsResponsesT<
  A0 = Channels,
  C0 = Channels,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getChannelsDecoder<
  A0 = Channels,
  C0 = Channels,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetChannelsResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getChannelsDefaultResponses as unknown) as GetChannelsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getChannelsDefaultDecoder = () => getChannelsDecoder();

/****************************************************************
 * createChannel
 */

// Request type definition
export type CreateChannelT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: ChannelDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, ChannelDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createChannelDefaultResponses = {
  201: ChannelDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateChannelResponsesT<
  A0 = ChannelDetails,
  C0 = ChannelDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createChannelDecoder<
  A0 = ChannelDetails,
  C0 = ChannelDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateChannelResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createChannelDefaultResponses as unknown) as CreateChannelResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createChannelDefaultDecoder = () => createChannelDecoder();

/****************************************************************
 * getChannelsCSV
 */

// Request type definition
export type GetChannelsCSVT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getChannelsCSVDefaultResponses = {
  200: t.undefined,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetChannelsCSVResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  429: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
};

export function getChannelsCSVDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
>(
  overrideTypes:
    | Partial<GetChannelsCSVResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<429, A3, "X-Request-Id">
  | r.IResponseType<500, A4, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getChannelsCSVDefaultResponses as unknown) as GetChannelsCSVResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A3, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A4, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getChannelsCSVDefaultDecoder = () => getChannelsCSVDecoder();

/****************************************************************
 * deleteChannel
 */

// Request type definition
export type DeleteChannelT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly channelcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteChannelDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteChannelResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteChannelDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteChannelResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteChannelDefaultResponses as unknown) as DeleteChannelResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteChannelDefaultDecoder = () => deleteChannelDecoder();

/****************************************************************
 * getChannel
 */

// Request type definition
export type GetChannelT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly channelcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, ChannelDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getChannelDefaultResponses = {
  200: ChannelDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetChannelResponsesT<
  A0 = ChannelDetails,
  C0 = ChannelDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getChannelDecoder<
  A0 = ChannelDetails,
  C0 = ChannelDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetChannelResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getChannelDefaultResponses as unknown) as GetChannelResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getChannelDefaultDecoder = () => getChannelDecoder();

/****************************************************************
 * updateChannel
 */

// Request type definition
export type UpdateChannelT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly channelcode: string;
    readonly body: ChannelDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, ChannelDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateChannelDefaultResponses = {
  200: ChannelDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateChannelResponsesT<
  A0 = ChannelDetails,
  C0 = ChannelDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateChannelDecoder<
  A0 = ChannelDetails,
  C0 = ChannelDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateChannelResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateChannelDefaultResponses as unknown) as UpdateChannelResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateChannelDefaultDecoder = () => updateChannelDecoder();

/****************************************************************
 * getChannelPaymentServiceProviders
 */

// Request type definition
export type GetChannelPaymentServiceProvidersT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly channelcode: string;
    readonly pspCode?: string;
    readonly pspName?: string;
    readonly pspEnabled?: boolean;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, ChannelPspList, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getChannelPaymentServiceProvidersDefaultResponses = {
  200: ChannelPspList,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetChannelPaymentServiceProvidersResponsesT<
  A0 = ChannelPspList,
  C0 = ChannelPspList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getChannelPaymentServiceProvidersDecoder<
  A0 = ChannelPspList,
  C0 = ChannelPspList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetChannelPaymentServiceProvidersResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getChannelPaymentServiceProvidersDefaultResponses as unknown) as GetChannelPaymentServiceProvidersResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getChannelPaymentServiceProvidersDefaultDecoder = () =>
  getChannelPaymentServiceProvidersDecoder();

/****************************************************************
 * getChannelPaymentServiceProvidersCSV
 */

// Request type definition
export type GetChannelPaymentServiceProvidersCSVT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly channelcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getChannelPaymentServiceProvidersCSVDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetChannelPaymentServiceProvidersCSVResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getChannelPaymentServiceProvidersCSVDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetChannelPaymentServiceProvidersCSVResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getChannelPaymentServiceProvidersCSVDefaultResponses as unknown) as GetChannelPaymentServiceProvidersCSVResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getChannelPaymentServiceProvidersCSVDefaultDecoder = () =>
  getChannelPaymentServiceProvidersCSVDecoder();

/****************************************************************
 * getChannelPaymentTypes
 */

// Request type definition
export type GetChannelPaymentTypesT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly channelcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PspChannelPaymentTypes, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getChannelPaymentTypesDefaultResponses = {
  200: PspChannelPaymentTypes,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetChannelPaymentTypesResponsesT<
  A0 = PspChannelPaymentTypes,
  C0 = PspChannelPaymentTypes,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getChannelPaymentTypesDecoder<
  A0 = PspChannelPaymentTypes,
  C0 = PspChannelPaymentTypes,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetChannelPaymentTypesResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getChannelPaymentTypesDefaultResponses as unknown) as GetChannelPaymentTypesResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getChannelPaymentTypesDefaultDecoder = () =>
  getChannelPaymentTypesDecoder();

/****************************************************************
 * createChannelPaymentType
 */

// Request type definition
export type CreateChannelPaymentTypeT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly channelcode: string;
    readonly body: PspChannelPaymentTypes;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, PspChannelPaymentTypes, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createChannelPaymentTypeDefaultResponses = {
  201: PspChannelPaymentTypes,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateChannelPaymentTypeResponsesT<
  A0 = PspChannelPaymentTypes,
  C0 = PspChannelPaymentTypes,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  409: t.Type<A5, C5>;
  429: t.Type<A6, C6>;
  500: t.Type<A7, C7>;
};

export function createChannelPaymentTypeDecoder<
  A0 = PspChannelPaymentTypes,
  C0 = PspChannelPaymentTypes,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateChannelPaymentTypeResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6,
          A7,
          C7
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<409, A5, "X-Request-Id">
  | r.IResponseType<429, A6, "X-Request-Id">
  | r.IResponseType<500, A7, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createChannelPaymentTypeDefaultResponses as unknown) as CreateChannelPaymentTypeResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6,
      A7,
      C7
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A5, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A6, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A7, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(
              r.composeResponseDecoders(d201, d400),
              d401
            ),
            d403
          ),
          d404
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createChannelPaymentTypeDefaultDecoder = () =>
  createChannelPaymentTypeDecoder();

/****************************************************************
 * deleteChannelPaymentType
 */

// Request type definition
export type DeleteChannelPaymentTypeT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly channelcode: string;
    readonly paymenttypecode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteChannelPaymentTypeDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteChannelPaymentTypeResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteChannelPaymentTypeDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteChannelPaymentTypeResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteChannelPaymentTypeDefaultResponses as unknown) as DeleteChannelPaymentTypeResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteChannelPaymentTypeDefaultDecoder = () =>
  deleteChannelPaymentTypeDecoder();

/****************************************************************
 * getFtpServers
 */

// Request type definition
export type GetFtpServersT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, FtpServers, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getFtpServersDefaultResponses = {
  200: FtpServers,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetFtpServersResponsesT<
  A0 = FtpServers,
  C0 = FtpServers,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getFtpServersDecoder<
  A0 = FtpServers,
  C0 = FtpServers,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetFtpServersResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getFtpServersDefaultResponses as unknown) as GetFtpServersResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getFtpServersDefaultDecoder = () => getFtpServersDecoder();

/****************************************************************
 * createFtpServer
 */

// Request type definition
export type CreateFtpServerT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: FtpServer;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, FtpServer, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createFtpServerDefaultResponses = {
  201: FtpServer,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateFtpServerResponsesT<
  A0 = FtpServer,
  C0 = FtpServer,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createFtpServerDecoder<
  A0 = FtpServer,
  C0 = FtpServer,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateFtpServerResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createFtpServerDefaultResponses as unknown) as CreateFtpServerResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createFtpServerDefaultDecoder = () => createFtpServerDecoder();

/****************************************************************
 * deleteFtpServer
 */

// Request type definition
export type DeleteFtpServerT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly host: string;
    readonly port: number;
    readonly service: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteFtpServerDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteFtpServerResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteFtpServerDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteFtpServerResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteFtpServerDefaultResponses as unknown) as DeleteFtpServerResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteFtpServerDefaultDecoder = () => deleteFtpServerDecoder();

/****************************************************************
 * getFtpServer
 */

// Request type definition
export type GetFtpServerT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly host: string;
    readonly port: number;
    readonly service: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, FtpServer, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getFtpServerDefaultResponses = {
  200: FtpServer,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetFtpServerResponsesT<
  A0 = FtpServer,
  C0 = FtpServer,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getFtpServerDecoder<
  A0 = FtpServer,
  C0 = FtpServer,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetFtpServerResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getFtpServerDefaultResponses as unknown) as GetFtpServerResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getFtpServerDefaultDecoder = () => getFtpServerDecoder();

/****************************************************************
 * updateFtpServer
 */

// Request type definition
export type UpdateFtpServerT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly host: string;
    readonly port: number;
    readonly service: string;
    readonly body: FtpServer;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, FtpServer, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateFtpServerDefaultResponses = {
  200: FtpServer,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateFtpServerResponsesT<
  A0 = FtpServer,
  C0 = FtpServer,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateFtpServerDecoder<
  A0 = FtpServer,
  C0 = FtpServer,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateFtpServerResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateFtpServerDefaultResponses as unknown) as UpdateFtpServerResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateFtpServerDefaultDecoder = () => updateFtpServerDecoder();

/****************************************************************
 * getConfigurationKeys
 */

// Request type definition
export type GetConfigurationKeysT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, ConfigurationKeys, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getConfigurationKeysDefaultResponses = {
  200: ConfigurationKeys,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetConfigurationKeysResponsesT<
  A0 = ConfigurationKeys,
  C0 = ConfigurationKeys,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getConfigurationKeysDecoder<
  A0 = ConfigurationKeys,
  C0 = ConfigurationKeys,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetConfigurationKeysResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getConfigurationKeysDefaultResponses as unknown) as GetConfigurationKeysResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getConfigurationKeysDefaultDecoder = () =>
  getConfigurationKeysDecoder();

/****************************************************************
 * createConfigurationKey
 */

// Request type definition
export type CreateConfigurationKeyT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: ConfigurationKey;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, ConfigurationKey, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createConfigurationKeyDefaultResponses = {
  201: ConfigurationKey,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateConfigurationKeyResponsesT<
  A0 = ConfigurationKey,
  C0 = ConfigurationKey,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createConfigurationKeyDecoder<
  A0 = ConfigurationKey,
  C0 = ConfigurationKey,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateConfigurationKeyResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createConfigurationKeyDefaultResponses as unknown) as CreateConfigurationKeyResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createConfigurationKeyDefaultDecoder = () =>
  createConfigurationKeyDecoder();

/****************************************************************
 * deleteConfigurationKey
 */

// Request type definition
export type DeleteConfigurationKeyT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly category: string;
    readonly key: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteConfigurationKeyDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteConfigurationKeyResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteConfigurationKeyDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteConfigurationKeyResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteConfigurationKeyDefaultResponses as unknown) as DeleteConfigurationKeyResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteConfigurationKeyDefaultDecoder = () =>
  deleteConfigurationKeyDecoder();

/****************************************************************
 * getConfigurationKey
 */

// Request type definition
export type GetConfigurationKeyT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly category: string;
    readonly key: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, ConfigurationKey, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getConfigurationKeyDefaultResponses = {
  200: ConfigurationKey,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetConfigurationKeyResponsesT<
  A0 = ConfigurationKey,
  C0 = ConfigurationKey,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getConfigurationKeyDecoder<
  A0 = ConfigurationKey,
  C0 = ConfigurationKey,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetConfigurationKeyResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getConfigurationKeyDefaultResponses as unknown) as GetConfigurationKeyResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getConfigurationKeyDefaultDecoder = () =>
  getConfigurationKeyDecoder();

/****************************************************************
 * updateConfigurationKey
 */

// Request type definition
export type UpdateConfigurationKeyT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly category: string;
    readonly key: string;
    readonly body: ConfigurationKeyBase;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, ConfigurationKeyBase, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateConfigurationKeyDefaultResponses = {
  200: ConfigurationKeyBase,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateConfigurationKeyResponsesT<
  A0 = ConfigurationKeyBase,
  C0 = ConfigurationKeyBase,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateConfigurationKeyDecoder<
  A0 = ConfigurationKeyBase,
  C0 = ConfigurationKeyBase,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateConfigurationKeyResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateConfigurationKeyDefaultResponses as unknown) as UpdateConfigurationKeyResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateConfigurationKeyDefaultDecoder = () =>
  updateConfigurationKeyDecoder();

/****************************************************************
 * getPaymentTypes
 */

// Request type definition
export type GetPaymentTypesT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PaymentTypes, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getPaymentTypesDefaultResponses = {
  200: PaymentTypes,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetPaymentTypesResponsesT<
  A0 = PaymentTypes,
  C0 = PaymentTypes,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getPaymentTypesDecoder<
  A0 = PaymentTypes,
  C0 = PaymentTypes,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetPaymentTypesResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaymentTypesDefaultResponses as unknown) as GetPaymentTypesResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getPaymentTypesDefaultDecoder = () => getPaymentTypesDecoder();

/****************************************************************
 * createPaymentType
 */

// Request type definition
export type CreatePaymentTypeT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: PaymentType;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, PaymentType, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createPaymentTypeDefaultResponses = {
  201: PaymentType,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreatePaymentTypeResponsesT<
  A0 = PaymentType,
  C0 = PaymentType,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createPaymentTypeDecoder<
  A0 = PaymentType,
  C0 = PaymentType,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreatePaymentTypeResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createPaymentTypeDefaultResponses as unknown) as CreatePaymentTypeResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createPaymentTypeDefaultDecoder = () => createPaymentTypeDecoder();

/****************************************************************
 * uploadHistory
 */

// Request type definition
export type UploadHistoryT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const uploadHistoryDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UploadHistoryResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function uploadHistoryDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UploadHistoryResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((uploadHistoryDefaultResponses as unknown) as UploadHistoryResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const uploadHistoryDefaultDecoder = () => uploadHistoryDecoder();

/****************************************************************
 * deletePaymentType
 */

// Request type definition
export type DeletePaymentTypeT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly paymentTypeCode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deletePaymentTypeDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeletePaymentTypeResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deletePaymentTypeDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeletePaymentTypeResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deletePaymentTypeDefaultResponses as unknown) as DeletePaymentTypeResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deletePaymentTypeDefaultDecoder = () => deletePaymentTypeDecoder();

/****************************************************************
 * getPaymentType
 */

// Request type definition
export type GetPaymentTypeT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly paymentTypeCode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PaymentType, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getPaymentTypeDefaultResponses = {
  200: PaymentType,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetPaymentTypeResponsesT<
  A0 = PaymentType,
  C0 = PaymentType,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getPaymentTypeDecoder<
  A0 = PaymentType,
  C0 = PaymentType,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetPaymentTypeResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaymentTypeDefaultResponses as unknown) as GetPaymentTypeResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getPaymentTypeDefaultDecoder = () => getPaymentTypeDecoder();

/****************************************************************
 * updatePaymentType
 */

// Request type definition
export type UpdatePaymentTypeT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly paymentTypeCode: string;
    readonly body: PaymentTypeBase;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PaymentType, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updatePaymentTypeDefaultResponses = {
  200: PaymentType,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdatePaymentTypeResponsesT<
  A0 = PaymentType,
  C0 = PaymentType,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updatePaymentTypeDecoder<
  A0 = PaymentType,
  C0 = PaymentType,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdatePaymentTypeResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updatePaymentTypeDefaultResponses as unknown) as UpdatePaymentTypeResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updatePaymentTypeDefaultDecoder = () => updatePaymentTypeDecoder();

/****************************************************************
 * getPdds
 */

// Request type definition
export type GetPddsT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Pdds, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getPddsDefaultResponses = {
  200: Pdds,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetPddsResponsesT<
  A0 = Pdds,
  C0 = Pdds,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getPddsDecoder<
  A0 = Pdds,
  C0 = Pdds,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<GetPddsResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPddsDefaultResponses as unknown) as GetPddsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getPddsDefaultDecoder = () => getPddsDecoder();

/****************************************************************
 * createPdd
 */

// Request type definition
export type CreatePddT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: Pdd;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, Pdd, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createPddDefaultResponses = {
  201: Pdd,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreatePddResponsesT<
  A0 = Pdd,
  C0 = Pdd,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createPddDecoder<
  A0 = Pdd,
  C0 = Pdd,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreatePddResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createPddDefaultResponses as unknown) as CreatePddResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createPddDefaultDecoder = () => createPddDecoder();

/****************************************************************
 * deletePdd
 */

// Request type definition
export type DeletePddT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly id_pdd: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deletePddDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeletePddResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deletePddDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeletePddResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deletePddDefaultResponses as unknown) as DeletePddResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deletePddDefaultDecoder = () => deletePddDecoder();

/****************************************************************
 * getPdd
 */

// Request type definition
export type GetPddT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly id_pdd: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Pdd, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getPddDefaultResponses = {
  200: Pdd,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetPddResponsesT<
  A0 = Pdd,
  C0 = Pdd,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getPddDecoder<
  A0 = Pdd,
  C0 = Pdd,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetPddResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5, A6, C6>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPddDefaultResponses as unknown) as GetPddResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getPddDefaultDecoder = () => getPddDecoder();

/****************************************************************
 * updatePdd
 */

// Request type definition
export type UpdatePddT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly id_pdd: string;
    readonly body: PddBase;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PddBase, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updatePddDefaultResponses = {
  200: PddBase,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdatePddResponsesT<
  A0 = PddBase,
  C0 = PddBase,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updatePddDecoder<
  A0 = PddBase,
  C0 = PddBase,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdatePddResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updatePddDefaultResponses as unknown) as UpdatePddResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updatePddDefaultDecoder = () => updatePddDecoder();

/****************************************************************
 * getWfespPlugins
 */

// Request type definition
export type GetWfespPluginsT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, WfespPluginConfs, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getWfespPluginsDefaultResponses = {
  200: WfespPluginConfs,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetWfespPluginsResponsesT<
  A0 = WfespPluginConfs,
  C0 = WfespPluginConfs,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getWfespPluginsDecoder<
  A0 = WfespPluginConfs,
  C0 = WfespPluginConfs,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetWfespPluginsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getWfespPluginsDefaultResponses as unknown) as GetWfespPluginsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getWfespPluginsDefaultDecoder = () => getWfespPluginsDecoder();

/****************************************************************
 * createWfespPlugin
 */

// Request type definition
export type CreateWfespPluginT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: WfespPluginConf;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, WfespPluginConf, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createWfespPluginDefaultResponses = {
  201: WfespPluginConf,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateWfespPluginResponsesT<
  A0 = WfespPluginConf,
  C0 = WfespPluginConf,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createWfespPluginDecoder<
  A0 = WfespPluginConf,
  C0 = WfespPluginConf,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateWfespPluginResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createWfespPluginDefaultResponses as unknown) as CreateWfespPluginResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createWfespPluginDefaultDecoder = () => createWfespPluginDecoder();

/****************************************************************
 * deleteWfespPlugin
 */

// Request type definition
export type DeleteWfespPluginT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly idServPlugin: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteWfespPluginDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteWfespPluginResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteWfespPluginDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteWfespPluginResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteWfespPluginDefaultResponses as unknown) as DeleteWfespPluginResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteWfespPluginDefaultDecoder = () => deleteWfespPluginDecoder();

/****************************************************************
 * getWfespPlugin
 */

// Request type definition
export type GetWfespPluginT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly idServPlugin: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, WfespPluginConf, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getWfespPluginDefaultResponses = {
  200: WfespPluginConf,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetWfespPluginResponsesT<
  A0 = WfespPluginConf,
  C0 = WfespPluginConf,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getWfespPluginDecoder<
  A0 = WfespPluginConf,
  C0 = WfespPluginConf,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetWfespPluginResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getWfespPluginDefaultResponses as unknown) as GetWfespPluginResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getWfespPluginDefaultDecoder = () => getWfespPluginDecoder();

/****************************************************************
 * updateWfespPlugin
 */

// Request type definition
export type UpdateWfespPluginT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly idServPlugin: string;
    readonly body: WfespPluginConfBase;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, WfespPluginConfBase, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateWfespPluginDefaultResponses = {
  200: WfespPluginConfBase,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateWfespPluginResponsesT<
  A0 = WfespPluginConfBase,
  C0 = WfespPluginConfBase,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateWfespPluginDecoder<
  A0 = WfespPluginConfBase,
  C0 = WfespPluginConfBase,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateWfespPluginResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateWfespPluginDefaultResponses as unknown) as UpdateWfespPluginResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateWfespPluginDefaultDecoder = () => updateWfespPluginDecoder();

/****************************************************************
 * getCounterpartTables
 */

// Request type definition
export type GetCounterpartTablesT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly idcounterparttable?: string;
    readonly creditorinstitutioncode?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CounterpartTables, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCounterpartTablesDefaultResponses = {
  200: CounterpartTables,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetCounterpartTablesResponsesT<
  A0 = CounterpartTables,
  C0 = CounterpartTables,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getCounterpartTablesDecoder<
  A0 = CounterpartTables,
  C0 = CounterpartTables,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCounterpartTablesResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCounterpartTablesDefaultResponses as unknown) as GetCounterpartTablesResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCounterpartTablesDefaultDecoder = () =>
  getCounterpartTablesDecoder();

/****************************************************************
 * createCounterpartTable
 */

// Request type definition
export type CreateCounterpartTableT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createCounterpartTableDefaultResponses = {
  201: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateCounterpartTableResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createCounterpartTableDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateCounterpartTableResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createCounterpartTableDefaultResponses as unknown) as CreateCounterpartTableResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createCounterpartTableDefaultDecoder = () =>
  createCounterpartTableDecoder();

/****************************************************************
 * deleteCounterpartTable
 */

// Request type definition
export type DeleteCounterpartTableT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly idcounterparttable: string;
    readonly creditorinstitutioncode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteCounterpartTableDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteCounterpartTableResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  409: t.Type<A5, C5>;
  429: t.Type<A6, C6>;
  500: t.Type<A7, C7>;
};

export function deleteCounterpartTableDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteCounterpartTableResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6,
          A7,
          C7
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<409, A5, "X-Request-Id">
  | r.IResponseType<429, A6, "X-Request-Id">
  | r.IResponseType<500, A7, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteCounterpartTableDefaultResponses as unknown) as DeleteCounterpartTableResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6,
      A7,
      C7
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A5, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A6, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A7, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(
              r.composeResponseDecoders(d200, d400),
              d401
            ),
            d403
          ),
          d404
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteCounterpartTableDefaultDecoder = () =>
  deleteCounterpartTableDecoder();

/****************************************************************
 * getCounterpartTable
 */

// Request type definition
export type GetCounterpartTableT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly idcounterparttable: string;
    readonly creditorinstitutioncode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCounterpartTableDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetCounterpartTableResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getCounterpartTableDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCounterpartTableResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCounterpartTableDefaultResponses as unknown) as GetCounterpartTableResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCounterpartTableDefaultDecoder = () =>
  getCounterpartTableDecoder();

/****************************************************************
 * getCreditorInstitutions
 */

// Request type definition
export type GetCreditorInstitutionsT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly code?: string;
    readonly name?: string;
    readonly enabled?: boolean;
    readonly orderby?: string;
    readonly ordering?: string;
    readonly limit?: number;
    readonly page: number;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CreditorInstitutions, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCreditorInstitutionsDefaultResponses = {
  200: CreditorInstitutions,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetCreditorInstitutionsResponsesT<
  A0 = CreditorInstitutions,
  C0 = CreditorInstitutions,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getCreditorInstitutionsDecoder<
  A0 = CreditorInstitutions,
  C0 = CreditorInstitutions,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCreditorInstitutionsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCreditorInstitutionsDefaultResponses as unknown) as GetCreditorInstitutionsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCreditorInstitutionsDefaultDecoder = () =>
  getCreditorInstitutionsDecoder();

/****************************************************************
 * createCreditorInstitution
 */

// Request type definition
export type CreateCreditorInstitutionT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: CreditorInstitutionDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, CreditorInstitutionDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createCreditorInstitutionDefaultResponses = {
  201: CreditorInstitutionDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateCreditorInstitutionResponsesT<
  A0 = CreditorInstitutionDetails,
  C0 = CreditorInstitutionDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createCreditorInstitutionDecoder<
  A0 = CreditorInstitutionDetails,
  C0 = CreditorInstitutionDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateCreditorInstitutionResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createCreditorInstitutionDefaultResponses as unknown) as CreateCreditorInstitutionResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createCreditorInstitutionDefaultDecoder = () =>
  createCreditorInstitutionDecoder();

/****************************************************************
 * massiveUploadCbillCsv
 */

// Request type definition
export type MassiveUploadCbillCsvT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly incremental?: boolean;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const massiveUploadCbillCsvDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type MassiveUploadCbillCsvResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function massiveUploadCbillCsvDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        MassiveUploadCbillCsvResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((massiveUploadCbillCsvDefaultResponses as unknown) as MassiveUploadCbillCsvResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const massiveUploadCbillCsvDefaultDecoder = () =>
  massiveUploadCbillCsvDecoder();

/****************************************************************
 * massiveCreateIbans
 */

// Request type definition
export type MassiveCreateIbansT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const massiveCreateIbansDefaultResponses = {
  201: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type MassiveCreateIbansResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function massiveCreateIbansDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        MassiveCreateIbansResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((massiveCreateIbansDefaultResponses as unknown) as MassiveCreateIbansResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const massiveCreateIbansDefaultDecoder = () =>
  massiveCreateIbansDecoder();

/****************************************************************
 * massiveCreateIbansCsv
 */

// Request type definition
export type MassiveCreateIbansCsvT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const massiveCreateIbansCsvDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type MassiveCreateIbansCsvResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function massiveCreateIbansCsvDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        MassiveCreateIbansCsvResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((massiveCreateIbansCsvDefaultResponses as unknown) as MassiveCreateIbansCsvResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const massiveCreateIbansCsvDefaultDecoder = () =>
  massiveCreateIbansCsvDecoder();

/****************************************************************
 * upsertIbanLabel
 */

// Request type definition
export type UpsertIbanLabelT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: IbanLabel;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, IbanLabel, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const upsertIbanLabelDefaultResponses = {
  200: IbanLabel,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpsertIbanLabelResponsesT<
  A0 = IbanLabel,
  C0 = IbanLabel,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function upsertIbanLabelDecoder<
  A0 = IbanLabel,
  C0 = IbanLabel,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpsertIbanLabelResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((upsertIbanLabelDefaultResponses as unknown) as UpsertIbanLabelResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const upsertIbanLabelDefaultDecoder = () => upsertIbanLabelDecoder();

/****************************************************************
 * getCreditorInstitutionsView
 */

// Request type definition
export type GetCreditorInstitutionsViewT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly creditorInstitutionCode?: string;
    readonly paBrokerCode?: string;
    readonly stationCode?: string;
    readonly enabled?: boolean;
    readonly auxDigit?: number;
    readonly applicationCode?: number;
    readonly segregationCode?: number;
    readonly mod4?: boolean;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CreditorInstitutionsView, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCreditorInstitutionsViewDefaultResponses = {
  200: CreditorInstitutionsView,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetCreditorInstitutionsViewResponsesT<
  A0 = CreditorInstitutionsView,
  C0 = CreditorInstitutionsView,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getCreditorInstitutionsViewDecoder<
  A0 = CreditorInstitutionsView,
  C0 = CreditorInstitutionsView,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCreditorInstitutionsViewResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCreditorInstitutionsViewDefaultResponses as unknown) as GetCreditorInstitutionsViewResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCreditorInstitutionsViewDefaultDecoder = () =>
  getCreditorInstitutionsViewDecoder();

/****************************************************************
 * deleteCreditorInstitution
 */

// Request type definition
export type DeleteCreditorInstitutionT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteCreditorInstitutionDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteCreditorInstitutionResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteCreditorInstitutionDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteCreditorInstitutionResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteCreditorInstitutionDefaultResponses as unknown) as DeleteCreditorInstitutionResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteCreditorInstitutionDefaultDecoder = () =>
  deleteCreditorInstitutionDecoder();

/****************************************************************
 * getCreditorInstitution
 */

// Request type definition
export type GetCreditorInstitutionT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CreditorInstitutionDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCreditorInstitutionDefaultResponses = {
  200: CreditorInstitutionDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetCreditorInstitutionResponsesT<
  A0 = CreditorInstitutionDetails,
  C0 = CreditorInstitutionDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getCreditorInstitutionDecoder<
  A0 = CreditorInstitutionDetails,
  C0 = CreditorInstitutionDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCreditorInstitutionResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCreditorInstitutionDefaultResponses as unknown) as GetCreditorInstitutionResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCreditorInstitutionDefaultDecoder = () =>
  getCreditorInstitutionDecoder();

/****************************************************************
 * updateCreditorInstitution
 */

// Request type definition
export type UpdateCreditorInstitutionT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
    readonly body: CreditorInstitutionDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CreditorInstitutionDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateCreditorInstitutionDefaultResponses = {
  200: CreditorInstitutionDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateCreditorInstitutionResponsesT<
  A0 = CreditorInstitutionDetails,
  C0 = CreditorInstitutionDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateCreditorInstitutionDecoder<
  A0 = CreditorInstitutionDetails,
  C0 = CreditorInstitutionDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateCreditorInstitutionResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateCreditorInstitutionDefaultResponses as unknown) as UpdateCreditorInstitutionResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateCreditorInstitutionDefaultDecoder = () =>
  updateCreditorInstitutionDecoder();

/****************************************************************
 * getCreditorInstitutionEncodings
 */

// Request type definition
export type GetCreditorInstitutionEncodingsT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CreditorInstitutionEncodings, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCreditorInstitutionEncodingsDefaultResponses = {
  200: CreditorInstitutionEncodings,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetCreditorInstitutionEncodingsResponsesT<
  A0 = CreditorInstitutionEncodings,
  C0 = CreditorInstitutionEncodings,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getCreditorInstitutionEncodingsDecoder<
  A0 = CreditorInstitutionEncodings,
  C0 = CreditorInstitutionEncodings,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCreditorInstitutionEncodingsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCreditorInstitutionEncodingsDefaultResponses as unknown) as GetCreditorInstitutionEncodingsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCreditorInstitutionEncodingsDefaultDecoder = () =>
  getCreditorInstitutionEncodingsDecoder();

/****************************************************************
 * createCreditorInstitutionEncoding
 */

// Request type definition
export type CreateCreditorInstitutionEncodingT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
    readonly body: Encoding;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, Encoding, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createCreditorInstitutionEncodingDefaultResponses = {
  201: Encoding,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateCreditorInstitutionEncodingResponsesT<
  A0 = Encoding,
  C0 = Encoding,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createCreditorInstitutionEncodingDecoder<
  A0 = Encoding,
  C0 = Encoding,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateCreditorInstitutionEncodingResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createCreditorInstitutionEncodingDefaultResponses as unknown) as CreateCreditorInstitutionEncodingResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createCreditorInstitutionEncodingDefaultDecoder = () =>
  createCreditorInstitutionEncodingDecoder();

/****************************************************************
 * deleteCreditorInstitutionEncoding
 */

// Request type definition
export type DeleteCreditorInstitutionEncodingT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
    readonly encodingcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteCreditorInstitutionEncodingDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteCreditorInstitutionEncodingResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteCreditorInstitutionEncodingDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteCreditorInstitutionEncodingResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteCreditorInstitutionEncodingDefaultResponses as unknown) as DeleteCreditorInstitutionEncodingResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteCreditorInstitutionEncodingDefaultDecoder = () =>
  deleteCreditorInstitutionEncodingDecoder();

/****************************************************************
 * getCreditorInstitutionsIbans
 */

// Request type definition
export type GetCreditorInstitutionsIbansT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Ibans, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCreditorInstitutionsIbansDefaultResponses = {
  200: Ibans,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetCreditorInstitutionsIbansResponsesT<
  A0 = Ibans,
  C0 = Ibans,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getCreditorInstitutionsIbansDecoder<
  A0 = Ibans,
  C0 = Ibans,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCreditorInstitutionsIbansResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCreditorInstitutionsIbansDefaultResponses as unknown) as GetCreditorInstitutionsIbansResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCreditorInstitutionsIbansDefaultDecoder = () =>
  getCreditorInstitutionsIbansDecoder();

/****************************************************************
 * createCreditorInstitutionsIbans
 */

// Request type definition
export type CreateCreditorInstitutionsIbansT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
    readonly body: IbanEnhanced;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, IbanEnhanced, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<422, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createCreditorInstitutionsIbansDefaultResponses = {
  201: IbanEnhanced,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  422: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateCreditorInstitutionsIbansResponsesT<
  A0 = IbanEnhanced,
  C0 = IbanEnhanced,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = ProblemJson,
  C6 = ProblemJson,
  A7 = undefined,
  C7 = undefined,
  A8 = ProblemJson,
  C8 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  409: t.Type<A5, C5>;
  422: t.Type<A6, C6>;
  429: t.Type<A7, C7>;
  500: t.Type<A8, C8>;
};

export function createCreditorInstitutionsIbansDecoder<
  A0 = IbanEnhanced,
  C0 = IbanEnhanced,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = ProblemJson,
  C6 = ProblemJson,
  A7 = undefined,
  C7 = undefined,
  A8 = ProblemJson,
  C8 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateCreditorInstitutionsIbansResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6,
          A7,
          C7,
          A8,
          C8
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<409, A5, "X-Request-Id">
  | r.IResponseType<422, A6, "X-Request-Id">
  | r.IResponseType<429, A7, "X-Request-Id">
  | r.IResponseType<500, A8, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createCreditorInstitutionsIbansDefaultResponses as unknown) as CreateCreditorInstitutionsIbansResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6,
      A7,
      C7,
      A8,
      C8
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A5, "X-Request-Id">
  >;

  const d422 = (type[422].name === "undefined"
    ? r.constantResponseDecoder<undefined, 422, "X-Request-Id">(422, undefined)
    : r.ioResponseDecoder<
        422,
        typeof type[422]["_A"],
        typeof type[422]["_O"],
        "X-Request-Id"
      >(422, type[422])) as r.ResponseDecoder<
    r.IResponseType<422, A6, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A7, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A8, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(
              r.composeResponseDecoders(
                r.composeResponseDecoders(d201, d400),
                d401
              ),
              d403
            ),
            d404
          ),
          d409
        ),
        d422
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createCreditorInstitutionsIbansDefaultDecoder = () =>
  createCreditorInstitutionsIbansDecoder();

/****************************************************************
 * getIbans
 */

// Request type definition
export type GetIbansT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly creditorinstitutioncode: string;
    readonly label?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, IbansEnhanced, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getIbansDefaultResponses = {
  200: IbansEnhanced,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetIbansResponsesT<
  A0 = IbansEnhanced,
  C0 = IbansEnhanced,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getIbansDecoder<
  A0 = IbansEnhanced,
  C0 = IbansEnhanced,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetIbansResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getIbansDefaultResponses as unknown) as GetIbansResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getIbansDefaultDecoder = () => getIbansDecoder();

/****************************************************************
 * updateCreditorInstitutionsIbans
 */

// Request type definition
export type UpdateCreditorInstitutionsIbansT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
    readonly ibanId: string;
    readonly body: IbanEnhanced;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, IbanEnhanced, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<422, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateCreditorInstitutionsIbansDefaultResponses = {
  201: IbanEnhanced,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  422: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateCreditorInstitutionsIbansResponsesT<
  A0 = IbanEnhanced,
  C0 = IbanEnhanced,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = ProblemJson,
  C6 = ProblemJson,
  A7 = undefined,
  C7 = undefined,
  A8 = ProblemJson,
  C8 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  409: t.Type<A5, C5>;
  422: t.Type<A6, C6>;
  429: t.Type<A7, C7>;
  500: t.Type<A8, C8>;
};

export function updateCreditorInstitutionsIbansDecoder<
  A0 = IbanEnhanced,
  C0 = IbanEnhanced,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = ProblemJson,
  C6 = ProblemJson,
  A7 = undefined,
  C7 = undefined,
  A8 = ProblemJson,
  C8 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateCreditorInstitutionsIbansResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6,
          A7,
          C7,
          A8,
          C8
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<409, A5, "X-Request-Id">
  | r.IResponseType<422, A6, "X-Request-Id">
  | r.IResponseType<429, A7, "X-Request-Id">
  | r.IResponseType<500, A8, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateCreditorInstitutionsIbansDefaultResponses as unknown) as UpdateCreditorInstitutionsIbansResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6,
      A7,
      C7,
      A8,
      C8
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A5, "X-Request-Id">
  >;

  const d422 = (type[422].name === "undefined"
    ? r.constantResponseDecoder<undefined, 422, "X-Request-Id">(422, undefined)
    : r.ioResponseDecoder<
        422,
        typeof type[422]["_A"],
        typeof type[422]["_O"],
        "X-Request-Id"
      >(422, type[422])) as r.ResponseDecoder<
    r.IResponseType<422, A6, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A7, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A8, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(
              r.composeResponseDecoders(
                r.composeResponseDecoders(d201, d400),
                d401
              ),
              d403
            ),
            d404
          ),
          d409
        ),
        d422
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateCreditorInstitutionsIbansDefaultDecoder = () =>
  updateCreditorInstitutionsIbansDecoder();

/****************************************************************
 * deleteCreditorInstitutionsIban
 */

// Request type definition
export type DeleteCreditorInstitutionsIbanT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
    readonly ibanValue: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteCreditorInstitutionsIbanDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteCreditorInstitutionsIbanResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteCreditorInstitutionsIbanDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteCreditorInstitutionsIbanResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteCreditorInstitutionsIbanDefaultResponses as unknown) as DeleteCreditorInstitutionsIbanResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteCreditorInstitutionsIbanDefaultDecoder = () =>
  deleteCreditorInstitutionsIbanDecoder();

/****************************************************************
 * getCreditorInstitutionStations
 */

// Request type definition
export type GetCreditorInstitutionStationsT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CreditorInstitutionStationList, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCreditorInstitutionStationsDefaultResponses = {
  200: CreditorInstitutionStationList,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetCreditorInstitutionStationsResponsesT<
  A0 = CreditorInstitutionStationList,
  C0 = CreditorInstitutionStationList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getCreditorInstitutionStationsDecoder<
  A0 = CreditorInstitutionStationList,
  C0 = CreditorInstitutionStationList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCreditorInstitutionStationsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCreditorInstitutionStationsDefaultResponses as unknown) as GetCreditorInstitutionStationsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCreditorInstitutionStationsDefaultDecoder = () =>
  getCreditorInstitutionStationsDecoder();

/****************************************************************
 * createCreditorInstitutionStation
 */

// Request type definition
export type CreateCreditorInstitutionStationT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
    readonly body: CreditorInstitutionStationEdit;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, CreditorInstitutionStationEdit, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createCreditorInstitutionStationDefaultResponses = {
  201: CreditorInstitutionStationEdit,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateCreditorInstitutionStationResponsesT<
  A0 = CreditorInstitutionStationEdit,
  C0 = CreditorInstitutionStationEdit,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createCreditorInstitutionStationDecoder<
  A0 = CreditorInstitutionStationEdit,
  C0 = CreditorInstitutionStationEdit,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateCreditorInstitutionStationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createCreditorInstitutionStationDefaultResponses as unknown) as CreateCreditorInstitutionStationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createCreditorInstitutionStationDefaultDecoder = () =>
  createCreditorInstitutionStationDecoder();

/****************************************************************
 * deleteCreditorInstitutionStation
 */

// Request type definition
export type DeleteCreditorInstitutionStationT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
    readonly stationcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteCreditorInstitutionStationDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteCreditorInstitutionStationResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteCreditorInstitutionStationDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteCreditorInstitutionStationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteCreditorInstitutionStationDefaultResponses as unknown) as DeleteCreditorInstitutionStationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteCreditorInstitutionStationDefaultDecoder = () =>
  deleteCreditorInstitutionStationDecoder();

/****************************************************************
 * updateCreditorInstitutionStation
 */

// Request type definition
export type UpdateCreditorInstitutionStationT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly creditorinstitutioncode: string;
    readonly stationcode: string;
    readonly body: CreditorInstitutionStationEdit;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CreditorInstitutionStationEdit, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateCreditorInstitutionStationDefaultResponses = {
  200: CreditorInstitutionStationEdit,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateCreditorInstitutionStationResponsesT<
  A0 = CreditorInstitutionStationEdit,
  C0 = CreditorInstitutionStationEdit,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateCreditorInstitutionStationDecoder<
  A0 = CreditorInstitutionStationEdit,
  C0 = CreditorInstitutionStationEdit,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateCreditorInstitutionStationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateCreditorInstitutionStationDefaultResponses as unknown) as UpdateCreditorInstitutionStationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateCreditorInstitutionStationDefaultDecoder = () =>
  updateCreditorInstitutionStationDecoder();

/****************************************************************
 * getCreditorInstitutionByPostalEncoding
 */

// Request type definition
export type GetCreditorInstitutionByPostalEncodingT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly encodingcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CreditorInstitutionList, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCreditorInstitutionByPostalEncodingDefaultResponses = {
  200: CreditorInstitutionList,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetCreditorInstitutionByPostalEncodingResponsesT<
  A0 = CreditorInstitutionList,
  C0 = CreditorInstitutionList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getCreditorInstitutionByPostalEncodingDecoder<
  A0 = CreditorInstitutionList,
  C0 = CreditorInstitutionList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCreditorInstitutionByPostalEncodingResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCreditorInstitutionByPostalEncodingDefaultResponses as unknown) as GetCreditorInstitutionByPostalEncodingResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCreditorInstitutionByPostalEncodingDefaultDecoder = () =>
  getCreditorInstitutionByPostalEncodingDecoder();

/****************************************************************
 * getCreditorInstitutionsByIban
 */

// Request type definition
export type GetCreditorInstitutionsByIbanT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly iban: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, CreditorInstitutionList, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getCreditorInstitutionsByIbanDefaultResponses = {
  200: CreditorInstitutionList,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetCreditorInstitutionsByIbanResponsesT<
  A0 = CreditorInstitutionList,
  C0 = CreditorInstitutionList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getCreditorInstitutionsByIbanDecoder<
  A0 = CreditorInstitutionList,
  C0 = CreditorInstitutionList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetCreditorInstitutionsByIbanResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCreditorInstitutionsByIbanDefaultResponses as unknown) as GetCreditorInstitutionsByIbanResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getCreditorInstitutionsByIbanDefaultDecoder = () =>
  getCreditorInstitutionsByIbanDecoder();

/****************************************************************
 * getIcas
 */

// Request type definition
export type GetIcasT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly idica?: string;
    readonly creditorinstitutioncode?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Icas, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getIcasDefaultResponses = {
  200: Icas,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetIcasResponsesT<
  A0 = Icas,
  C0 = Icas,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getIcasDecoder<
  A0 = Icas,
  C0 = Icas,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<GetIcasResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getIcasDefaultResponses as unknown) as GetIcasResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getIcasDefaultDecoder = () => getIcasDecoder();

/****************************************************************
 * createIca
 */

// Request type definition
export type CreateIcaT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly force?: boolean;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createIcaDefaultResponses = {
  201: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateIcaResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createIcaDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateIcaResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createIcaDefaultResponses as unknown) as CreateIcaResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createIcaDefaultDecoder = () => createIcaDecoder();

/****************************************************************
 * verifyIca
 */

// Request type definition
export type VerifyIcaT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const verifyIcaDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type VerifyIcaResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function verifyIcaDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        VerifyIcaResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((verifyIcaDefaultResponses as unknown) as VerifyIcaResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const verifyIcaDefaultDecoder = () => verifyIcaDecoder();

/****************************************************************
 * massiveVerifyIcas
 */

// Request type definition
export type MassiveVerifyIcasT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const massiveVerifyIcasDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type MassiveVerifyIcasResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function massiveVerifyIcasDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        MassiveVerifyIcasResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((massiveVerifyIcasDefaultResponses as unknown) as MassiveVerifyIcasResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const massiveVerifyIcasDefaultDecoder = () => massiveVerifyIcasDecoder();

/****************************************************************
 * massiveCreateIcas
 */

// Request type definition
export type MassiveCreateIcasT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const massiveCreateIcasDefaultResponses = {
  201: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type MassiveCreateIcasResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function massiveCreateIcasDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        MassiveCreateIcasResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((massiveCreateIcasDefaultResponses as unknown) as MassiveCreateIcasResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const massiveCreateIcasDefaultDecoder = () => massiveCreateIcasDecoder();

/****************************************************************
 * checkXSD
 */

// Request type definition
export type CheckXSDT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly file: { uri: string; name: string; type: string };
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, XSDValidation, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const checkXSDDefaultResponses = {
  200: XSDValidation,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type CheckXSDResponsesT<
  A0 = XSDValidation,
  C0 = XSDValidation,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function checkXSDDecoder<
  A0 = XSDValidation,
  C0 = XSDValidation,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CheckXSDResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((checkXSDDefaultResponses as unknown) as CheckXSDResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const checkXSDDefaultDecoder = () => checkXSDDecoder();

/****************************************************************
 * deleteIca
 */

// Request type definition
export type DeleteIcaT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly idica: string;
    readonly creditorinstitutioncode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteIcaDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteIcaResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  409: t.Type<A5, C5>;
  429: t.Type<A6, C6>;
  500: t.Type<A7, C7>;
};

export function deleteIcaDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteIcaResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6,
          A7,
          C7
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<409, A5, "X-Request-Id">
  | r.IResponseType<429, A6, "X-Request-Id">
  | r.IResponseType<500, A7, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteIcaDefaultResponses as unknown) as DeleteIcaResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6,
      A7,
      C7
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A5, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A6, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A7, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(
              r.composeResponseDecoders(d200, d400),
              d401
            ),
            d403
          ),
          d404
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteIcaDefaultDecoder = () => deleteIcaDecoder();

/****************************************************************
 * getIca
 */

// Request type definition
export type GetIcaT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly idica: string;
    readonly creditorinstitutioncode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getIcaDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetIcaResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getIcaDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetIcaResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5, A6, C6>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getIcaDefaultResponses as unknown) as GetIcaResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getIcaDefaultDecoder = () => getIcaDecoder();

/****************************************************************
 * healthCheck
 */

// Request type definition
export type HealthCheckT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, AppInfo, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const healthCheckDefaultResponses = {
  200: AppInfo,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type HealthCheckResponsesT<
  A0 = AppInfo,
  C0 = AppInfo,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function healthCheckDecoder<
  A0 = AppInfo,
  C0 = AppInfo,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        HealthCheckResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((healthCheckDefaultResponses as unknown) as HealthCheckResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const healthCheckDefaultDecoder = () => healthCheckDecoder();

/****************************************************************
 * getPaymentServiceProviders
 */

// Request type definition
export type GetPaymentServiceProvidersT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly code?: string;
    readonly name?: string;
    readonly taxCode?: string;
    readonly orderby?: string;
    readonly ordering?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PaymentServiceProviders, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getPaymentServiceProvidersDefaultResponses = {
  200: PaymentServiceProviders,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetPaymentServiceProvidersResponsesT<
  A0 = PaymentServiceProviders,
  C0 = PaymentServiceProviders,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getPaymentServiceProvidersDecoder<
  A0 = PaymentServiceProviders,
  C0 = PaymentServiceProviders,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetPaymentServiceProvidersResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaymentServiceProvidersDefaultResponses as unknown) as GetPaymentServiceProvidersResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getPaymentServiceProvidersDefaultDecoder = () =>
  getPaymentServiceProvidersDecoder();

/****************************************************************
 * createPaymentServiceProvider
 */

// Request type definition
export type CreatePaymentServiceProviderT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: PaymentServiceProviderDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, PaymentServiceProviderDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createPaymentServiceProviderDefaultResponses = {
  201: PaymentServiceProviderDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreatePaymentServiceProviderResponsesT<
  A0 = PaymentServiceProviderDetails,
  C0 = PaymentServiceProviderDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createPaymentServiceProviderDecoder<
  A0 = PaymentServiceProviderDetails,
  C0 = PaymentServiceProviderDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreatePaymentServiceProviderResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createPaymentServiceProviderDefaultResponses as unknown) as CreatePaymentServiceProviderResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createPaymentServiceProviderDefaultDecoder = () =>
  createPaymentServiceProviderDecoder();

/****************************************************************
 * getPaymentServiceProvidersView
 */

// Request type definition
export type GetPaymentServiceProvidersViewT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly pspCode?: string;
    readonly pspBrokerCode?: string;
    readonly channelCode?: string;
    readonly paymentType?: string;
    readonly paymentModel?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PaymentServiceProvidersView, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getPaymentServiceProvidersViewDefaultResponses = {
  200: PaymentServiceProvidersView,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetPaymentServiceProvidersViewResponsesT<
  A0 = PaymentServiceProvidersView,
  C0 = PaymentServiceProvidersView,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getPaymentServiceProvidersViewDecoder<
  A0 = PaymentServiceProvidersView,
  C0 = PaymentServiceProvidersView,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetPaymentServiceProvidersViewResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaymentServiceProvidersViewDefaultResponses as unknown) as GetPaymentServiceProvidersViewResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getPaymentServiceProvidersViewDefaultDecoder = () =>
  getPaymentServiceProvidersViewDecoder();

/****************************************************************
 * deletePaymentServiceProvider
 */

// Request type definition
export type DeletePaymentServiceProviderT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly pspcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deletePaymentServiceProviderDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeletePaymentServiceProviderResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deletePaymentServiceProviderDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeletePaymentServiceProviderResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deletePaymentServiceProviderDefaultResponses as unknown) as DeletePaymentServiceProviderResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deletePaymentServiceProviderDefaultDecoder = () =>
  deletePaymentServiceProviderDecoder();

/****************************************************************
 * getPaymentServiceProvider
 */

// Request type definition
export type GetPaymentServiceProviderT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly pspcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PaymentServiceProviderDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getPaymentServiceProviderDefaultResponses = {
  200: PaymentServiceProviderDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetPaymentServiceProviderResponsesT<
  A0 = PaymentServiceProviderDetails,
  C0 = PaymentServiceProviderDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getPaymentServiceProviderDecoder<
  A0 = PaymentServiceProviderDetails,
  C0 = PaymentServiceProviderDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetPaymentServiceProviderResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaymentServiceProviderDefaultResponses as unknown) as GetPaymentServiceProviderResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getPaymentServiceProviderDefaultDecoder = () =>
  getPaymentServiceProviderDecoder();

/****************************************************************
 * updatePaymentServiceProvider
 */

// Request type definition
export type UpdatePaymentServiceProviderT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly pspcode: string;
    readonly body: PaymentServiceProviderDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PaymentServiceProviderDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updatePaymentServiceProviderDefaultResponses = {
  200: PaymentServiceProviderDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdatePaymentServiceProviderResponsesT<
  A0 = PaymentServiceProviderDetails,
  C0 = PaymentServiceProviderDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updatePaymentServiceProviderDecoder<
  A0 = PaymentServiceProviderDetails,
  C0 = PaymentServiceProviderDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdatePaymentServiceProviderResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updatePaymentServiceProviderDefaultResponses as unknown) as UpdatePaymentServiceProviderResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updatePaymentServiceProviderDefaultDecoder = () =>
  updatePaymentServiceProviderDecoder();

/****************************************************************
 * getPaymentServiceProvidersChannels
 */

// Request type definition
export type GetPaymentServiceProvidersChannelsT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly pspcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PspChannelList, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getPaymentServiceProvidersChannelsDefaultResponses = {
  200: PspChannelList,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetPaymentServiceProvidersChannelsResponsesT<
  A0 = PspChannelList,
  C0 = PspChannelList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getPaymentServiceProvidersChannelsDecoder<
  A0 = PspChannelList,
  C0 = PspChannelList,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetPaymentServiceProvidersChannelsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaymentServiceProvidersChannelsDefaultResponses as unknown) as GetPaymentServiceProvidersChannelsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getPaymentServiceProvidersChannelsDefaultDecoder = () =>
  getPaymentServiceProvidersChannelsDecoder();

/****************************************************************
 * createPaymentServiceProvidersChannels
 */

// Request type definition
export type CreatePaymentServiceProvidersChannelsT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly pspcode: string;
    readonly body: PspChannelCode;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, PspChannelCode, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createPaymentServiceProvidersChannelsDefaultResponses = {
  201: PspChannelCode,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreatePaymentServiceProvidersChannelsResponsesT<
  A0 = PspChannelCode,
  C0 = PspChannelCode,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  409: t.Type<A5, C5>;
  429: t.Type<A6, C6>;
  500: t.Type<A7, C7>;
};

export function createPaymentServiceProvidersChannelsDecoder<
  A0 = PspChannelCode,
  C0 = PspChannelCode,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreatePaymentServiceProvidersChannelsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6,
          A7,
          C7
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<409, A5, "X-Request-Id">
  | r.IResponseType<429, A6, "X-Request-Id">
  | r.IResponseType<500, A7, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createPaymentServiceProvidersChannelsDefaultResponses as unknown) as CreatePaymentServiceProvidersChannelsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6,
      A7,
      C7
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A5, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A6, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A7, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(
              r.composeResponseDecoders(d201, d400),
              d401
            ),
            d403
          ),
          d404
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createPaymentServiceProvidersChannelsDefaultDecoder = () =>
  createPaymentServiceProvidersChannelsDecoder();

/****************************************************************
 * deletePaymentServiceProvidersChannels
 */

// Request type definition
export type DeletePaymentServiceProvidersChannelsT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly pspcode: string;
    readonly channelcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deletePaymentServiceProvidersChannelsDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeletePaymentServiceProvidersChannelsResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  409: t.Type<A5, C5>;
  429: t.Type<A6, C6>;
  500: t.Type<A7, C7>;
};

export function deletePaymentServiceProvidersChannelsDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined,
  A7 = ProblemJson,
  C7 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeletePaymentServiceProvidersChannelsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6,
          A7,
          C7
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<409, A5, "X-Request-Id">
  | r.IResponseType<429, A6, "X-Request-Id">
  | r.IResponseType<500, A7, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deletePaymentServiceProvidersChannelsDefaultResponses as unknown) as DeletePaymentServiceProvidersChannelsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6,
      A7,
      C7
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A5, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A6, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A7, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(
              r.composeResponseDecoders(d200, d400),
              d401
            ),
            d403
          ),
          d404
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deletePaymentServiceProvidersChannelsDefaultDecoder = () =>
  deletePaymentServiceProvidersChannelsDecoder();

/****************************************************************
 * updatePaymentServiceProvidersChannels
 */

// Request type definition
export type UpdatePaymentServiceProvidersChannelsT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly pspcode: string;
    readonly channelcode: string;
    readonly body: PspChannelPaymentTypes;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, PspChannelPaymentTypes, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updatePaymentServiceProvidersChannelsDefaultResponses = {
  200: PspChannelPaymentTypes,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdatePaymentServiceProvidersChannelsResponsesT<
  A0 = PspChannelPaymentTypes,
  C0 = PspChannelPaymentTypes,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updatePaymentServiceProvidersChannelsDecoder<
  A0 = PspChannelPaymentTypes,
  C0 = PspChannelPaymentTypes,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdatePaymentServiceProvidersChannelsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updatePaymentServiceProvidersChannelsDefaultResponses as unknown) as UpdatePaymentServiceProvidersChannelsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updatePaymentServiceProvidersChannelsDefaultDecoder = () =>
  updatePaymentServiceProvidersChannelsDecoder();

/****************************************************************
 * getRefreshGlobalConfig
 */

// Request type definition
export type GetRefreshGlobalConfigT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getRefreshGlobalConfigDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetRefreshGlobalConfigResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getRefreshGlobalConfigDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetRefreshGlobalConfigResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getRefreshGlobalConfigDefaultResponses as unknown) as GetRefreshGlobalConfigResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getRefreshGlobalConfigDefaultDecoder = () =>
  getRefreshGlobalConfigDecoder();

/****************************************************************
 * getJobTrigger
 */

// Request type definition
export type GetJobTriggerT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly jobtype: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
  | r.IResponseType<504, ProblemJson, "X-Request-Id">
>;

export const getJobTriggerDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson,
  504: ProblemJson
};

export type GetJobTriggerResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
  504: t.Type<A6, C6>;
};

export function getJobTriggerDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetJobTriggerResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
  | r.IResponseType<504, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getJobTriggerDefaultResponses as unknown) as GetJobTriggerResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  const d504 = (type[504].name === "undefined"
    ? r.constantResponseDecoder<undefined, 504, "X-Request-Id">(504, undefined)
    : r.ioResponseDecoder<
        504,
        typeof type[504]["_A"],
        typeof type[504]["_O"],
        "X-Request-Id"
      >(504, type[504])) as r.ResponseDecoder<
    r.IResponseType<504, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d429
      ),
      d500
    ),
    d504
  );
}

// Decodes the success response with the type defined in the specs
export const getJobTriggerDefaultDecoder = () => getJobTriggerDecoder();

/****************************************************************
 * getServices
 */

// Request type definition
export type GetServicesT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly pspcode?: string;
    readonly brokerpspcode?: string;
    readonly channelcode?: string;
    readonly paymentmethodchannel?: number;
    readonly paymenttypecode?: string;
    readonly pspflagftamp?: boolean;
    readonly channelapp?: boolean;
    readonly onus?: boolean;
    readonly flagio?: boolean;
    readonly flowid?: string;
    readonly minimumamount?: number;
    readonly maximumamount?: number;
    readonly languagecode?: string;
    readonly conventionCode?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Services, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getServicesDefaultResponses = {
  200: Services,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetServicesResponsesT<
  A0 = Services,
  C0 = Services,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getServicesDecoder<
  A0 = Services,
  C0 = Services,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetServicesResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getServicesDefaultResponses as unknown) as GetServicesResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getServicesDefaultDecoder = () => getServicesDecoder();

/****************************************************************
 * getStations
 */

// Request type definition
export type GetStationsT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit?: number;
    readonly page: number;
    readonly brokercode?: string;
    readonly brokerdescription?: string;
    readonly creditorinstitutioncode?: string;
    readonly code?: string;
    readonly ordering?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Stations, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getStationsDefaultResponses = {
  200: Stations,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetStationsResponsesT<
  A0 = Stations,
  C0 = Stations,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getStationsDecoder<
  A0 = Stations,
  C0 = Stations,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetStationsResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getStationsDefaultResponses as unknown) as GetStationsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d403
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getStationsDefaultDecoder = () => getStationsDecoder();

/****************************************************************
 * createStation
 */

// Request type definition
export type CreateStationT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: StationDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, StationDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createStationDefaultResponses = {
  201: StationDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateStationResponsesT<
  A0 = StationDetails,
  C0 = StationDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createStationDecoder<
  A0 = StationDetails,
  C0 = StationDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateStationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createStationDefaultResponses as unknown) as CreateStationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createStationDefaultDecoder = () => createStationDecoder();

/****************************************************************
 * getStationsCSV
 */

// Request type definition
export type GetStationsCSVT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getStationsCSVDefaultResponses = {
  200: t.undefined,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetStationsCSVResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  429: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
};

export function getStationsCSVDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
>(
  overrideTypes:
    | Partial<GetStationsCSVResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<429, A3, "X-Request-Id">
  | r.IResponseType<500, A4, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getStationsCSVDefaultResponses as unknown) as GetStationsCSVResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A3, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A4, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getStationsCSVDefaultDecoder = () => getStationsCSVDecoder();

/****************************************************************
 * deleteStation
 */

// Request type definition
export type DeleteStationT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly stationcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteStationDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteStationResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function deleteStationDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteStationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteStationDefaultResponses as unknown) as DeleteStationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteStationDefaultDecoder = () => deleteStationDecoder();

/****************************************************************
 * getStation
 */

// Request type definition
export type GetStationT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly stationcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, StationDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getStationDefaultResponses = {
  200: StationDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetStationResponsesT<
  A0 = StationDetails,
  C0 = StationDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getStationDecoder<
  A0 = StationDetails,
  C0 = StationDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetStationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getStationDefaultResponses as unknown) as GetStationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getStationDefaultDecoder = () => getStationDecoder();

/****************************************************************
 * updateStation
 */

// Request type definition
export type UpdateStationT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly stationcode: string;
    readonly body: StationDetails;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, StationDetails, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateStationDefaultResponses = {
  200: StationDetails,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateStationResponsesT<
  A0 = StationDetails,
  C0 = StationDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateStationDecoder<
  A0 = StationDetails,
  C0 = StationDetails,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateStationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateStationDefaultResponses as unknown) as UpdateStationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateStationDefaultDecoder = () => updateStationDecoder();

/****************************************************************
 * getStationCreditorInstitutions
 */

// Request type definition
export type GetStationCreditorInstitutionsT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly stationcode: string;
    readonly limit?: number;
    readonly page: number;
    readonly ciNameOrCF?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, StationCreditorInstitutions, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getStationCreditorInstitutionsDefaultResponses = {
  200: StationCreditorInstitutions,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetStationCreditorInstitutionsResponsesT<
  A0 = StationCreditorInstitutions,
  C0 = StationCreditorInstitutions,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getStationCreditorInstitutionsDecoder<
  A0 = StationCreditorInstitutions,
  C0 = StationCreditorInstitutions,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetStationCreditorInstitutionsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getStationCreditorInstitutionsDefaultResponses as unknown) as GetStationCreditorInstitutionsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getStationCreditorInstitutionsDefaultDecoder = () =>
  getStationCreditorInstitutionsDecoder();

/****************************************************************
 * getStationCreditorInstitutionsCSV
 */

// Request type definition
export type GetStationCreditorInstitutionsCSVT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly stationcode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getStationCreditorInstitutionsCSVDefaultResponses = {
  200: t.undefined,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetStationCreditorInstitutionsCSVResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getStationCreditorInstitutionsCSVDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetStationCreditorInstitutionsCSVResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getStationCreditorInstitutionsCSVDefaultResponses as unknown) as GetStationCreditorInstitutionsCSVResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getStationCreditorInstitutionsCSVDefaultDecoder = () =>
  getStationCreditorInstitutionsCSVDecoder();

/****************************************************************
 * getStationCreditorInstitutionRelation
 */

// Request type definition
export type GetStationCreditorInstitutionRelationT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly stationcode: string;
    readonly creditorinstitutioncode: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, StationCreditorInstitutions, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getStationCreditorInstitutionRelationDefaultResponses = {
  200: StationCreditorInstitutions,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetStationCreditorInstitutionRelationResponsesT<
  A0 = StationCreditorInstitutions,
  C0 = StationCreditorInstitutions,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function getStationCreditorInstitutionRelationDecoder<
  A0 = StationCreditorInstitutions,
  C0 = StationCreditorInstitutions,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetStationCreditorInstitutionRelationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getStationCreditorInstitutionRelationDefaultResponses as unknown) as GetStationCreditorInstitutionRelationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getStationCreditorInstitutionRelationDefaultDecoder = () =>
  getStationCreditorInstitutionRelationDecoder();
